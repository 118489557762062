<template>
  <v-app-bar app clipped-left clipped-right color="white">
    <v-spacer />
    <v-toolbar-title @click="navigateHome()">
      <v-img
        v-if="settings && !settings.logo_oft_file_uid"
        @click="navigateHome"
        contain
        height="50"
        style="cursor: pointer"
        :src="require('@/assets/nexus-connect-logo.svg')"
      />
      <v-img
        v-if="settings && settings.logo_oft_file_uid"
        @click="navigateHome"
        contain
        height="50"
        style="cursor: pointer"
        :src="logoUrl"
      />
    </v-toolbar-title>
    <v-spacer />
    <v-btn
      elevation="0"
      class="rounded-10"
      @click="logOut"
      v-if="settings?.manage_clients"
    >
      <v-icon class="mr-2"> mdi-logout </v-icon>
      Log out
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex"
import client from "@/lib/ApiClient"
export default {
  name: "AppBar",

  data() {
    return {
      logoUrl: null
    }
  },

  computed: {
    ...mapGetters(["settings"])
  },

  watch: {
    settings: {
      handler(value) {
        if (value && value.logo_oft_file_uid) this.fetchUrl()
      },
      deep: true
    }
  },

  methods: {
    navigateHome() {
      this.$router.push("/").catch(() => {})
    },

    logOut() {
      const slug = this.$route.path.replace("/", "")
      window.location = `/logout/?slug=${slug}`
    },

    async fetchUrl() {
      if (this.settings?.logo_oft_file_uid) {
        const oftResponse = await client.oft.getFileUrls({
          oft_file_uid: this.settings.logo_oft_file_uid
        })

        this.logoUrl = oftResponse.url
      }
    }
  }
}
</script>
