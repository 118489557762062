<template>
  <v-container
    fluid
    class="d-flex justify-center align-center flex-column"
    fill-height
  >
    <v-img
      v-if="settings && !settings.logo_oft_file_uid"
      style="flex-grow: 0"
      contain
      width="300"
      :src="require('@/assets/nexus-connect-logo.svg')"
    />
    <v-img
      v-if="settings && settings.logo_oft_file_uid"
      style="flex-grow: 0"
      contain
      width="300"
      :src="logoUrl"
    />
    <span
      class="title-font mt-4"
      style="font-size: 17px; letter-spacing: 0.25ch"
    >
      {{ settings.tenant_name }}
    </span>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
import client from "@/lib/ApiClient"

export default {
  name: "ConnectBranding",

  data() {
    return {
      logoUrl: null
    }
  },

  computed: {
    ...mapGetters(["settings"])
  },

  watch: {
    settings: {
      handler(value) {
        if (value && value.logo_oft_file_uid) this.fetchUrl()
      },
      deep: true
    }
  },

  methods: {
    async fetchUrl() {
      if (this.settings?.logo_oft_file_uid) {
        const oftResponse = await client.oft.getFileUrls({
          oft_file_uid: this.settings.logo_oft_file_uid
        })

        this.logoUrl = oftResponse.url
      }
    }
  },

  mounted() {
    if (this.settings?.logo_oft_file_uid) this.fetchUrl()
  }
}
</script>
